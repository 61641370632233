import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MODAL_BUY_CRYSTALS, MODAL_DONATE} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import {fetchData} from '../utils/api';
import './Modals.css'
import OverflowIcon from '../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../assets/icons/currency/crystal/Base';
import CrystalShadow from '../assets/icons/currency/crystal/Shadow';
import {getTG} from "../utils/utils";
import axios from 'axios';
import { formatNumberWithSpaces, formatNumberWithSpacesShort } from '../utils/mathUtils';
import { DefaultStateType, getDispatchObject, SET_TON, SET_XTR } from '../store/reducer';
import { tonConnectUI, wallet } from '../utils/connect';
import {Checkbox} from "@nextui-org/checkbox";

interface ModalParams {
  donation: string
  amount: number
  pack: string
  price: number
  discount: number | null
  original: number | null
}

// @ts-ignore
const tg = window["Telegram"]['WebApp'];

const BuyCrystalsModal: FC = () => {

    const { t } = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const tonRatio = useSelector((s: DefaultStateType) => s.tonRate)
    const xtrRatio = useSelector((s: DefaultStateType) => s.xtrRate)
    const [params, setParams] = useState<ModalParams>()
    const dispatch = useDispatch()

    const [selectedCurr, setSelectedCurr] = useState('ton')
    const paymentMethods = selectedCurr === 'ton' ? ['cryptobot', 'ton'] : 
      (selectedCurr === 'usdt' ? ['cryptobot'] : ['stars']);

    const [termsAgreed, setTermsAgreed] = useState(false);
    // const paymentMethods = ['cryptobot']
    // useEffect(() => {
    //   async function fetcher () {
    //     await fetchRatio()
    //   }

    //   fetcher()
    // }, [])

    // const fetchRatio = async () => {
    //   const response = await axios.get('https://okx.com/api/v5/market/ticker?instId=TON-USD-SWAP')
    //   console.log(response)
    // }
    
    const loadRate = async () => {
      const tonRate = await fetchData('/exchange/get_usdt_rate', { currency: 'TON'})
      if (tonRate.error) return
      dispatch(getDispatchObject(SET_TON, Number(tonRate.result.rate)))

      const xtrRate = await fetchData('/exchange/get_usdt_rate', { currency: 'XTR'})
      if (xtrRate.error) return
      dispatch(getDispatchObject(SET_XTR, Number(xtrRate.result.rate)))
    }
    
    useEffect(() => {
      if (activeModal === MODAL_BUY_CRYSTALS)
        loadRate()
    }, [activeModal])

    useEffect(() => {
      if (!activeModalParams) return
      setParams({
        donation: activeModalParams.donation,
        amount: activeModalParams.amount,
        pack: activeModalParams.pack,
        price: activeModalParams.price,
        discount: activeModalParams.discount,
        original: activeModalParams.original
      })
    }, [activeModalParams])

    const openPaymentMethod = async (option: string) => {
      console.log(selectedCurr + ' is selected')
      switch (option) {
        case 'wallet': {
          console.log('wallet action');
          break;
        }
        case 'cryptobot': {
          const response = await fetchData('/diamonds/buy_via_cryptobot', {
            currency: selectedCurr,
            packSize: params?.pack
          });

          if (response.error) {
            return;
          }

          const result = response.result;

          if (result) {
            getTG().openTelegramLink(result.pay_url)

          }
          break;
        }
        case 'ton': {
          const wallets = await tonConnectUI.getWallets()

          if (!wallets || !wallet) {
            try {
              await tonConnectUI.connectWallet()
            }
            catch {
              console.log('wallet not conenected')
              return
            }
          }

          const response = await fetchData('/diamonds/buy_via_tonconnect', {
            currency: selectedCurr,
            packSize: params?.pack
          });

          if (response.error) {
            return;
          }

          const result = response.result;

          if (result && result.tonTransaction) {

            tonConnectUI.sendTransaction(result.tonTransaction)

          }
          break;
        }
        case 'stars': {
          if (!termsAgreed) return

          const response = await fetchData('/diamonds/buy_via_telegram_stars', {
            packSize: params?.pack
          });

          if (response.error || !response.result?.link) return
          
          tg.openInvoice(response.result.link)

          break;
        }
        default: return
      }
    }

    return (
        <Modal
            isOpen={params && activeModal === MODAL_BUY_CRYSTALS}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(MODAL_DONATE)}
            motionProps={{
              variants: {
                enter: {
                    y: "var(--slide-enter)",
                    opacity: 1,
                    transition: {
                      y: {
                        type: null
                      }
                    }
                  },
                  exit: {
                    opacity: 0,
                    y: "var(--slide-exit)"
                  },
                }
              }}
            >
            <ModalContent className='ModalDonate--ellipse'>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-4">
                          <p className='text-3xl text-white font-bold text-center'>
                            {t('donatePurchase')}
                          </p>
                          <p className='text-sm text-[var(--secondary\_hint)] text-center'>
                            {t('donateSelected')}
                          </p>
                          <div className={[
                            "py-3 pl-3 pr-7 flex flex-row justify-between items-center relative border-1 border-solid border-white/10 rounded-2xl bg-gradient-to-b from-[#080A2300] to-[#080A2380]",
                            params && params.donation === 'two' ? 'ModalPentagonBg before:inset-0 before:bg-no-repeat before:bg-right' : "",
                            params && params.donation === 'chest' ? 'ModalTexturedBg before:inset-0' : ""
                          ].join(' ')}>
                            <div className='flex flex-row gap-2 items-center justify-center'>
                              <img width={56} height={56} className='object-contain' src={require(`../assets/images/donate/${params?.donation ?? 'one'}.png`)} alt="" />
                              <div className='flex flex-row gap-1'>
                                <p className='ModalDonate--amount text-xl/6 font-black'>
                                  {params?.amount ?? 0}
                                </p>
                                <OverflowIcon
                                  Base={<CrystalBase width='12px' height='20px' />}
                                  Shadow={<CrystalShadow width='26px' height='23px' />}
                                  shadowOffsetTop={11}
                                  shadowOffsetLeft={-7}
                                  width={12}
                                  height={20}
                                />
                              </div>
                            </div>
                            <div className='flex items-baseline gap-1'>
                              <p className='text-xl font-semibold text-white'>
                                ${params?.price}
                              </p>
                              {
                                params?.original ?
                                <p className='text-sm text-[#AAAAAA] strike-through'>
                                  ${params?.original}
                                </p> : null
                              }
                            </div>
                          </div>
                          <Tabs
                            aria-label="Choose currency"
                            classNames={{
                              tabList: "w-full rounded-[44px] p-[2px] bg-[#080A23]",
                              tab: "w-1/3 px-0 h-fit py-0 data-[selected=false]:bg-[#080A23] data-[selected=true]:bg-[#19192B]",
                              cursor: "bg-[#19192B] p-1 rounded-[40px]",
                              panel: "px-0"
                            }}
                            onSelectionChange={(key) => setSelectedCurr(key as string)}
                          >
                            <Tab key="ton" title={
                              <p className='BuildingsTab--name py-2.5 text-sm group-data-[selected=true]:font-semibold text-white'>
                                TON
                              </p>
                            }>
                            <div className='flex flex-row w-full justify-center items-center'>
                              <img width={28} height={28} className='object-contain rounded-full' src={require("../assets/images/crypto/ton.png")} alt="" />
                              <p className='text-2xl/7 font-black text-white ml-2 text-nowrap'>
                                {formatNumberWithSpacesShort((params?.price ?? 0) / tonRatio, 3)} TON
                              </p>
                              <p className='text-2xl/7 text-[var(--secondary\_hint)] ml-2 text-nowrap'>
                                = ${params?.price ?? 0}
                              </p>
                            </div>
                            </Tab>
                            <Tab key="usdt" title={
                              <p className='BuildingsTab--name py-2.5 text-sm group-data-[selected=true]:font-semibold text-white'>
                                USDT
                              </p>
                            }>
                              <div className='flex flex-row w-full justify-center items-center'>
                                <img width={28} height={28} className='object-contain rounded-full' src={require("../assets/images/crypto/usdt.png")} alt="" />
                                <p className='text-2xl/7 font-black text-white ml-2 text-nowrap'>
                                  {formatNumberWithSpacesShort((params?.price ?? 0), 3)} USDT
                                </p>
                              </div>
                            </Tab>
                            <Tab key="stars" title={
                              <p className='BuildingsTab--name py-2.5 text-sm group-data-[selected=true]:font-semibold text-white'>
                                Stars
                              </p>
                            }>
                              <div className='flex flex-col gap-4 w-full justify-center items-center'>
                                <div className='flex flex-row w-full justify-center items-center'>
                                  <img width={28} height={28} className='object-contain rounded-full' src={require("../assets/images/crypto/star.png")} alt="" />
                                  <p className='text-2xl/7 font-black text-white ml-2 text-nowrap'>
                                    {formatNumberWithSpacesShort((params?.price ?? 0) / xtrRatio, 3)} Stars
                                  </p>
                                </div>
                                <Checkbox 
                                  isSelected={termsAgreed} 
                                  onValueChange={setTermsAgreed}
                                  className=''
                                >
                                  <div className='flex flex-row w-fit gap-1 items-baseline'>
                                    <p>
                                      {t('termsIAgree')}
                                    </p>
                                    <a 
                                      className='text-white text-center underline' 
                                      href={t('termsLink')}
                                      target='_blank'
                                    >
                                      {t('termsOfUse')}
                                    </a>
                                  </div>
                                </Checkbox>
                              </div>
                            </Tab>
                          </Tabs>
                        </ModalBody>
                        <ModalFooter className='flex flex-row mb-6 pt-1 items-center justify-center gap-6'>
                          {
                            paymentMethods.map(item =>
                              <Button
                                className='opacity-100 bg-transparent p-0 w-fit h-fit flex-col gap-2'
                                onClick={() => openPaymentMethod(item)}
                              >
                                <img width={60} height={60} className='object-contain rounded-[19px]' src={require(`../assets/images/crypto/${item}.png`)} alt="" />
                                <p className='text-sm font-semibold text-white capitalize'>
                                  {item === 'ton' ? 'Tonconnect' : item}
                                </p>
                              </Button>)
                          }
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default BuyCrystalsModal;
