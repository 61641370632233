import React, {FC, useEffect, useReducer, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./panels/Home/Home";
import {ROUTE_BUILDINGS, ROUTE_BALANCE, ROUTE_BOOSTS, ROUTE_FRIENDS, ROUTE_FRIENDS_BONUS, ROUTE_HOME, ROUTE_LEAGUE, ROUTE_STATISTICS, ROUTE_TASKS, MODAL_OFFLINE_MINING, ROUTE_ONBOARDING, ROUTE_PRIVATE_SALE, ROUTE_PUBLIC_SALE, MODAL_BALANCE, ROUTE_GIVEAWAY, ROUTE_PROFILE, ROUTE_ACHIEVEMENTS, ROUTE_PREMIUM, SNACKBAR_ACHIEVEMENT} from "./routes";
import Friends from "./panels/Friends/Friends";
import Buildings from "./panels/Buildings/Buildings"
import Tasks from "./panels/Tasks/Tasks";
import Boosts from "./panels/Boosts/Boosts";
import Statistics from "./panels/Statistics/Statistics";
import FriendsBonus from "./panels/Friends/FriendsBonus";
import {socket} from "./utils/socket";
import {fetchData} from "./utils/api";
import {DefaultStateType, getDispatchObject, SET_BUILDINGS, ADD_GOLD, SET_IMPROVEMENTS, SET_AUTOCLICK_ENT_TIME, SET_DAILY_ENERGY, SET_ENERGY_LEFT, SET_GOLD, SET_GOLD_PER_CLICK, SET_LEVEL, SET_RECHARGING_ENERGY_PER_SECOND, SET_ROCKET, SET_SCREEN_POPUP, SET_USDT, SET_GOLD_PER_HOUR, SET_PRIZEPOOL, SET_PRIZEPOOL_WINNERS, SET_MY_POSITION, SET_LEVELS, SET_CRYSTALS, SET_BOOSTS, SET_DAILIES, SET_TTC, SET_RATE, SET_INVITE_LINK, SET_EMISSION, SET_STAGE, SET_FRIENDS, SET_TASKS, SET_TON, ADD_ENERGY_LEFT, SET_PREMIUM_BUILDINGS, SET_LIMITED_BUILDINGS, SET_FRIENDS_COUNT, SET_CURRENT_DAILY, SET_XTR, SET_AD_TIMER, SET_PREMIUM, SET_ACHIEVEMENTS, UPDATE_ACHIEVEMENT} from "./store/reducer";
import {useDispatch, useSelector} from "react-redux";
import store from './store/store'
import BoostBuyConfirmModal from "./modals/BoostBuyConfirmModal";
import ScreenLoader from "./components/ScreenLoader/ScreenLoader";
import Balance from "./panels/Balance/Balance";
import League from "./panels/League/League";
import Navigation from "./panels/General/Navigation/Navigation";
import useModal from './hooks/useModal';
import OfflineMiningModal from './modals/OfflineMiningModal';
import useImagePreloader, { preloadBatch } from './hooks/useImagePreloader';
import TasksDailyModal from './modals/TaskDailyModal';
import TasksDefaultModal from './modals/TasksDefaultModal';
import LevelUpModal from './modals/LevelUpModal';
import BoostsPremiumModal from './modals/BoostPremiumModal';
import BoostsNormalModal from './modals/BoostNormalModal';
import BoostsBuyBuildings from './modals/BuyBuildingsModal';
import { achievementsServerToFrontId, boostsServerToFrontId, donations, getBonusTypes, taskMapCodes } from './utils/utils';
import DonateModal from './modals/DonateModal';
import Onboarding from './panels/Onboarding/Onboarding';
import ExchangeModal from './modals/ExchangeModal';
import WithdrawalModal from './modals/WithdrawalModal';
import PrivateSale from './panels/Emission/PrivateSale';
import PublicSale from './panels/Emission/PublicSale';
import BuyCrystalsModal from './modals/BuyCrystalsModal';
import BankDescriptionModal from './modals/BankDescriptionModal';
import BalanceUpdateModal from './modals/BalanceUpdateModal';
import dayjs from 'dayjs';
import { setWallet, tonConnectUI } from './utils/connect';
import { sendAnalytics, setReferral } from './utils/gtag';
import Profile from './panels/Profile/Profile';
import Achievements from './panels/Achievements/Achievements';
import AchievementModal from './modals/AchievementsModal';
import AdModal from './modals/AdModal';
import Giveaway from './panels/Giveaway/Giveaway';
import Premium from './panels/Profile/Premium';
import BuyCBCModal from './modals/BuyCBCmodal';
import SnackBar from './components/Snackbar/Snackbar';
import useSnackbar from './hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import ErrorBar from './components/Snackbar/ErrorBar';
// @ts-ignore
const tg = window["Telegram"]['WebApp'];
let timerId: any = null;

function importAll(r: __WebpackModuleApi.RequireContext) : string[] {
    return r.keys().map(r) as string[];
}

const buildings = importAll(require.context('./assets/images/buildings', false, /\.(png|jpe?g|svg)$/));
const extras = importAll(require.context('./assets/images/extras', false, /\.(png|jpe?g|svg)$/));
const boosts = importAll(require.context('./assets/images/boosts', false, /\.(png|jpe?g|svg)$/));
const donate = importAll(require.context('./assets/images/donate', false, /\.(png|jpe?g|svg)$/));
const preload = importAll(require.context('./assets/images/preload', false, /\.(png|jpe?g|svg)$/));
const leagues = importAll(require.context('./assets/images/leagues', false, /\.(png|jpe?g|svg)$/));
const onboarding = importAll(require.context('./assets/images/onboarding', false, /\.(png|jpe?g|svg)$/));

const buildingsHardcodeReq = {
    7: 1, 
    9: 2,
    11: 3,
    13: 4,
    15: 5,
    17: 6,
    19: 7,
    31: 1,
    32: 2,
    33: 3,
    34: 4,
    35: 5,
    36: 6,
    37: 7,
    38: 8,
    39: 9,
    40: 10
} as { [key: number]: number}

const mapBuilding = (item: any, hidden: boolean, locked: boolean, asLimited: boolean): any => {
    return {
        id: item.id,
        imgSrc: require(`./assets/images/buildings/${item.code}.png`),
        name: item.code,
        profitRate: item.goldPerHour,
        piecePrice: item.price,
        upgradePrice: item.price,
        premium: item.isPremium,
        purchased: item.boughtCount,
        unlocked: item.unlocked ?? false,
        locked,
        hidden,
        friendsReq: item.id in buildingsHardcodeReq ? buildingsHardcodeReq[item.id] : null,
        achievementReq: item.id in buildingsHardcodeReq ? buildingsHardcodeReq[item.id]*5 + 5 : null,
        unlockPrice: item.unlockPrice ?? null,
        startsFrom: item.startAvailableAt ?? null,
        endsAt: item.stopAvailableAt ?? null,
        asLimited
    }
}

const App: FC = () => {

    const dispatch = useDispatch();
    // emergency brakes
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    
    const selector = useSelector((s: DefaultStateType) => s);
    const [progressInit, setProgressInit] = useState(0)
    const [progressData, setProgressData] = useState(0)
    const [loaderInit, setLoaderInit] = useState(false)
    const [loaderData, setLoaderData] = useState(true)
    const [showOnboarding, setShowOnboarding] = useState(true)

    const {setActiveModal, activeModalParams} = useModal();
    const {setActiveSnackbar} = useSnackbar();

    const { imagesPreloaded } = useImagePreloader([...preload, ...onboarding])

    const translation = useTranslation()

    useEffect(() => {
        if (loaderInit && loaderData && progressData + progressInit === 100) {
            dispatch(getDispatchObject(SET_SCREEN_POPUP, null));
        }
    }, [progressData, progressInit])

    async function loader () {
        if (tonConnectUI) {
            setWallet(tonConnectUI.wallet)
        }
        await fetchExchangeRate()
        setProgressInit(10);
        const res = await init()
        setLoaderInit(res)

        return res
    }

    useEffect(() => {
        loader().then((val) => {
            if (!val) return
            socket.connect();
            setProgressData(10);
            addEventListener();
        }
        )
    }, []);

    useEffect(() => {
        timerId = setInterval(() => {
            dispatch(getDispatchObject(ADD_ENERGY_LEFT, null));
        }, 1000);
    }, [])

    const init = async () => {
        if (!['ios', 'android'].includes(tg['platform']) && process.env.NODE_ENV !== 'development') {
            dispatch(getDispatchObject(SET_SCREEN_POPUP, "unSupportPlatform"));
            return false;
        }

        const levelsFetched = await getLevels()
        await fetchBuildings();
        setProgressInit(30);
        await fetchImprovements();
        await fetchPrizepools();
        await fetchPrizepoolWinners(levelsFetched);
        setProgressInit(40);
        await fetchMyLastWinnings(levelsFetched);
        await fetchBoosts()
        await fetchDailies()
        await fetchAchievements()
        setProgressInit(50);
        await fetchTasks();
        await fetchFriends(levelsFetched)

        await preloadBatch(leagues)
        setProgressInit(60);
        Promise.all([
            preloadBatch(buildings),
            preloadBatch(extras),
            preloadBatch(boosts),
            preloadBatch(donate)
        ])
        setProgressInit(70);
        return true
    }

    const addEventListener = () => {
        socket.on('disconnect', (reason: string) => {
            console.log('disconnect', reason);
            if (store.getState().crystals !== null && store.getState().dailyEnergy !== null) {
                console.log("FORCE UPDATE REQUIRED")
                forceUpdate()
            } else {
                dispatch(getDispatchObject(SET_SCREEN_POPUP, 'connection'));
            }
        });

        socket.on('disconnect-many-connections', () => {
            console.log('disconnect-many-connections')
            //router.replacePage(PAGE_ERROR, { type: 'manyConnectionsError' });
            dispatch(getDispatchObject(SET_SCREEN_POPUP, 'manyConnections'))
        });

        socket.on('connect', async () => {
            console.log('connect');
        });

        socket.on("connect_error", () => {
            console.log('connect error');
            if (store.getState().crystals !== null && store.getState().dailyEnergy !== null) {
                console.log("FORCE UPDATE REQUIRED")
                forceUpdate()
            } else {
                dispatch(getDispatchObject(SET_SCREEN_POPUP, 'connection'));
            }
        });

        socket.on("MININED_OFFLINE", (data) => {
            if (Math.floor(data?.minedGold ?? 0) > 0)
                setActiveModal(MODAL_OFFLINE_MINING, data);
                // setActiveModal(MODAL_LEVEL_UP, { bonuses: { tap: '+1', energy: '+500' }, level: 'iron' }) // TESTING
        })

        socket.on("UPDATE_SCORE", (data) => {
            console.log("UPDATE_SCORE", data)
            if (data.gold !== 0 && ((data.gold !== 1000 && data.gold !== 2000) || data.goldPerSecond !== 0)) setShowOnboarding(false)
            if ((data.gold === 1000 || data.gold === 2000 || data.gold === 0) && data.goldPerSecond === 0) {
                if (data.gold === 1000 || data.gold === 2000) {
                    setReferral()
                }
                sendAnalytics('tutorial_begin')
            }
            dispatch(getDispatchObject(SET_LEVEL, data.level));
            dispatch(getDispatchObject(SET_GOLD, data.gold));
            dispatch(getDispatchObject(SET_USDT, data.usdt));
            dispatch(getDispatchObject(SET_TTC, data.ttc));
            dispatch(getDispatchObject(SET_CRYSTALS, data.donatediamonds));
            dispatch(getDispatchObject(SET_ROCKET, data.rocket));
            dispatch(getDispatchObject(SET_ENERGY_LEFT, data.energyLeft));
            dispatch(getDispatchObject(SET_DAILY_ENERGY, data.dailyEnergy));
            dispatch(getDispatchObject(SET_GOLD_PER_CLICK, data.goldPerClick));
            dispatch(getDispatchObject(SET_GOLD_PER_HOUR, data.goldPerSecond * 3600));
            dispatch(getDispatchObject(SET_AUTOCLICK_ENT_TIME, data.autoclickEndTime));
            dispatch(getDispatchObject(SET_RECHARGING_ENERGY_PER_SECOND, data['rechargingEnergyPerSecond']));
            dispatch(getDispatchObject(SET_INVITE_LINK, data['inviteLink']));

            dispatch(getDispatchObject(SET_AD_TIMER, data['lastadrewardtime']))
            dispatch(getDispatchObject(SET_PREMIUM, { active: false, until: dayjs().add(7, 'd').toDate() }))  // FIX WHEN API UPDATES

            setProgressData(20);
            setProgressInit(80);
            setLoaderData(true)

        });

        socket.on("MINING", ({ minedGold, gold }) => {
            dispatch(getDispatchObject(ADD_GOLD, minedGold));
        })

        socket.on("ACHIEVEMENT", ({ achievements, goldPerSecond }) => {
            const s = store.getState()
            achievements.forEach((item: any) => {
                dispatch(getDispatchObject(UPDATE_ACHIEVEMENT, item.code))
                setActiveSnackbar(SNACKBAR_ACHIEVEMENT, { id: item.code })
            })
            dispatch(getDispatchObject(SET_GOLD_PER_HOUR, goldPerSecond * 3600))

        })

        socket.on("UPDATE_BALANCES", ({ balanceUpdated, value }) => {
            let diff = 0
            const s = store.getState()
            if (balanceUpdated === 'ttc' && s.ttc && s.ttc !== value) {
                diff = value - s.ttc
                dispatch(getDispatchObject(SET_TTC, value))
            }
            if (balanceUpdated === 'donatediamonds' && s.crystals && s.crystals !== value) {
                diff = value - (s.crystals)
                const item = donations.find(item => item.amount === diff)
                if (item) sendAnalytics('purchase', {
                    currency: 'USD',
                    value: diff,
                    items: [{
                        item_id: `crystals_${item.amount}`,
                        item_name: `${item.amount} Crystals Pack`
                    }]
                })
                dispatch(getDispatchObject(SET_CRYSTALS, value))
            }
            if (balanceUpdated === 'usdt' && s.usdt && s.usdt !== value) {
                diff = value - (s.usdt)
                dispatch(getDispatchObject(SET_USDT, value))
            }
            if (diff > 0) {
                setActiveModal(MODAL_BALANCE, { value: diff, balance: balanceUpdated})
            }
        })
    }

    const fetchBuildings = async () => {
        
        const response = await fetchData('/buildings/get_buildings');
        let lockStartFree = 9999
        if (response.error) return

        const premiumBuildings = response.result.filter((item: any )=> item.isPremium).sort((a: any, b: any) => a.goldPerHour - b.goldPerHour > 0 ? 1 : -1).map((item: any, index: number) => {
            const building = mapBuilding(item, false, false, false)
            return building
        })

        const limitedBuildings = response.result.filter((item: any )=> item.stopAvailableAt && item.startAvailableAt).sort((a: any, b: any) => a.goldPerHour - b.goldPerHour > 0 ? 1 : -1).map((item: any, index: number) => {
            let limitedShow = false
            const timeLeft = new Date(item.stopAvailableAt).getTime() - new Date().getTime()
            const timeStart = new Date(item.startAvailableAt).getTime() - new Date().getTime()
            if ((timeLeft > 0 && timeStart < 0) || item.boughtCount > 0) {
                limitedShow = true
            }
            const building = mapBuilding(item, !limitedShow, false, true)
            return building
        })

        const normalBuildings = response.result.filter((item: any )=> !item.isPremium && !item.stopAvailableAt).sort((a: any, b: any) => a.goldPerHour - b.goldPerHour > 0 ? 1 : -1).map((item: any, index: number) => {
            if (item.boughtCount === 0 && !item.isPremium && !item.stopAvailableAt && lockStartFree === 9999) lockStartFree = index + 1
            if (showOnboarding && item.boughtCount > 0) setShowOnboarding(false)

            const building = mapBuilding(item, (index > lockStartFree + 1), (index >= lockStartFree), false)
            return building
        })

        dispatch(getDispatchObject(SET_BUILDINGS, normalBuildings.reduce((res: any, item: any) => ({ ...res, [item.id]: item}), {})))
        dispatch(getDispatchObject(SET_PREMIUM_BUILDINGS, premiumBuildings.reduce((res: any, item: any) => ({ ...res, [item.id]: item}), {})))
        dispatch(getDispatchObject(SET_LIMITED_BUILDINGS, limitedBuildings.reduce((res: any, item: any) => ({ ...res, [item.id]: item}), {})))
    }

    const getLevels = async () => {
        const response = await fetchData('/misc/get_levels');
        dispatch(getDispatchObject(SET_LEVELS, response.result))
        return response.result.reduce((res: any, item: any) => ({ ...res, [item.level]: item.levelName}), {})
    }

    const fetchDailies = async () => {
        const response = await fetchData('/user/get_daily_rewards')
        if (response.error) return
        if (!response.result) {
            forceUpdate()
            return
        }
        const result = response.result.list.map((item: any) => {
            return {
                rewardLevel: item.rewardLevel,
                profit: item.goldAmount,
                unlockedAt: dayjs(item.unlockedAt).unix(),
                status: item.status
            }
        })

        dispatch(getDispatchObject(SET_DAILIES, result.sort((a: any, b: any) => a.rewardLevel - b.rewardLevel).reduce((res: any, item: any) => ({ ...res, [item.rewardLevel]: item}), {})))


        const now = dayjs().unix()
        const next = dayjs(response.result.currentTask.unlockedAt).unix()
        const reset = response.result.isReset
        const curr = {
            profit: response.result.currentTask.goldAmount,
            unlockedAt: reset ? (now + 24 * 60 * 60 - 1) : (next + (now < next ? 0 : 24 * 60 * 60)),
            available: reset ? true : (now > next ? true : false),
            rewardLevel: response.result.currentTask.rewardLevel
        }

        dispatch(getDispatchObject(SET_CURRENT_DAILY, curr))
    }

    const fetchExchangeRate = async () => {

        const tonRate = await fetchData('/exchange/get_usdt_rate', { currency: 'TON'})
        if (tonRate.error) return
        dispatch(getDispatchObject(SET_TON, Number(tonRate.result.rate)))


        const starsRate = await fetchData('/exchange/get_usdt_rate', { currency: 'XTR'})
        if (starsRate.error) return
        dispatch(getDispatchObject(SET_XTR, Number(starsRate.result.rate)))


        const response2 = await fetchData('/ttc/emission')
        if (!response2.result) return
        dispatch(getDispatchObject(SET_EMISSION, response2.result.emission))
    }

    const fetchImprovements = async () => {
        const response = await fetchData('/buildings/get_improvements');
        if (response.error) return

        const available = response.result.notBought.map((item: any) => {
            const imageSource = item.code.replace(/[0-9]/g, '')

            return {
            id: item.id,
            imgSrc: require(`./assets/images/buildings/${imageSource}.png`),
            name: item.code,
            piecePrice: item.price,
            description: `${item.code} speeds up the process by ${item.scalePercent}%`,
            requirement: `Need ${item.availableIfBuildingsCount} "${item.code}"`,
            premium: item.isPremium,
            scalePercent: item.scalePercent,
            buildingCount: item.availableIfBuildingsCount,
            buildingId: item.buildingId,
            status: "available"
        }})

        const bought = response.result.bought.map((item: any) => {
            const imageSource = item.code.replace(/[0-9]/g, '')

            return {
            id: item.id,
            imgSrc: require(`./assets/images/buildings/${imageSource}.png`),
            name: item.code,
            piecePrice: item.price,
            description: `${item.code} speeds up the process by ${item.scalePercent}%`,
            requirement: `Need ${item.availableIfBuildingsCount} "${item.code}"`,
            scalePercent: item.scalePercent,
            premium: item.isPremium,
            buildingCount: item.availableIfBuildingsCount,
            buildingId: item.buildingId,
            status: "bought"
        }})

        dispatch(getDispatchObject(SET_IMPROVEMENTS,
            {
                available: available.reduce((res: any, item: any) => ({ ...res, [item.id]: item}), {}),
                bought: bought.reduce((res: any, item: any) => ({ ...res, [item.id]: item}), {})
            }
        ))
    }

    const fetchFriends = async (levels: { [key: string]: string }) => {
        const response = await fetchData('/friends/get')
        if (response.error) return

        dispatch(getDispatchObject(SET_FRIENDS, response.result?.items && response.result.items.length > 0 ? response.result.items.map((item: any) => {
            return {
                name: item.firstName ? item.firstName : "Anonymous",
                photo: item.avatar ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + (item.avatar)) : null,
                crystals: item.profitDiamonds,
                level: levels[item.level],
                gold: item.goldPerSecond,
                premium: item.premium ?? false // FIX WHEN API
            }
        }) : []))

        dispatch(getDispatchObject(SET_FRIENDS_COUNT, response.result?.totalCount ?? 0))
    }

    const fetchPrizepools = async () => {
        const response = await fetchData('/prizepool/get_prizepool');
        if (response.error) return

        const result = response.result.map((item: any) => {
            return {
                league: item.levelName,
                value: item.prizePool ?? 0
            }
        })

        dispatch(getDispatchObject(SET_PRIZEPOOL, result.reduce((res: any, item: any) => ({ ...res, [item.league]: item.value}), {})))
    }

    const fetchPrizepoolWinners = async (levels: { [key: string]: string}) => {
        const response = await fetchData('/prizepool/get_top_winners');
        if (response.error) return

        const result = {} as { [key: string] : any}
        for (const [key, value] of Object.entries(response.result as { [key: string] : any})) {
            const levelName = levels[key]
            result[levelName] = value.map((item: any, index: number) => {
                return {
                    position: index + 1,
                    name: (item.first_name ? (item.first_name) : `Anonymous`) + (item.last_name && item.last_name !== ' ' ? (" " + item.last_name) : ""),
                    tg_id: item.user_id,
                    reward: Number(item.total_reward),
                    photo: item.file_path ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + (item.file_path)) : null,
                    premium: item.premium ?? false // FIX WHEN API UPDATES
                }
            })
        }

        dispatch(getDispatchObject(SET_PRIZEPOOL_WINNERS, result))
    }

    const fetchMyLastWinnings = async (levels: { [key: string]: string}) => {
        const response = await fetchData('/prizepool/get_my_position_in_top');
        if (response.error) return

        const result = response.result
        dispatch(getDispatchObject(SET_MY_POSITION, {
            league: levels[result.userLevel],
            id: result.userId,
            position: '100+',
            profit: Number(result.reward),
            name: result.firstName ?? "Anonymous",
            photo: result.avatar ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + result.avatar) : null
        }))
    }

    const fetchTasks = async () => {

        const response = await fetchData('/tasks/get', { locale: translation?.i18n?.language ?? 'en' })

        if (response.error) return

        const uncompleted = response.result.uncompletedTasks.map((item: any) => {
            const code = item.code in taskMapCodes ? item.code : 'fallback'
            return {
                code: item.code,
                type: taskMapCodes[code].type,
                action: taskMapCodes[code].action,
                bonus: item.goldReward,
                bonuses: getBonusTypes(item),
                startsFrom: item.startAvailableAt ? dayjs(item.startAvailableAt).unix() : null,
                endsAt: item.stopAvailableAt ? dayjs(item.stopAvailableAt).unix() : null,
                completed: false,
                link: item.link,
                imageLink: item.imageLink ?? null,
                dbType: item.type,
                locale: {
                    title: item.locale ? item.locale.title : (item.fallbackLocale?.title ?? translation.t('tasks--fallback')),
                    description: item.locale ? item.locale.description : (item.fallbackLocale?.description ?? translation.t('tasks--fallback')),
                }
            }
        })
        const completed = response.result.completedTasks.map((i: any) => {
            const item = i.task
            const code = item.code in taskMapCodes ? item.code : 'fallback'
            return {
                code: item.code,
                type: taskMapCodes[code].type,
                action: taskMapCodes[code].action,
                bonus: null,
                bonuses: null,
                startsFrom: item.startAvailableAt ? dayjs(item.startAvailableAt).unix() : null,
                endsAt: item.stopAvailableAt ? dayjs(item.stopAvailableAt).unix() : null,
                completed: true,
                link: item.link,
                imageLink: item.imageLink ?? null,
                dbType: item.type,
                locale: {
                    title: item.locale ? item.locale.title : (item.fallbackLocale?.title ?? translation.t('tasks--fallback')),
                    description: item.locale ? item.locale.description : (item.fallbackLocale?.description ?? translation.t('tasks--fallback')),
                }
            }
        })
        dispatch(getDispatchObject(SET_TASKS, [...uncompleted, ...completed].reduce((res: any, item) => ({ ...res, [item.code]: item}), {})))
    }

    const fetchAchievements = async () => {
        const response = await fetchData('/achievements/get')

        if (response.error || !response.result) return

        const achs = {} as { [key: string]: Object }
        Object.values(response.result.achievements).forEach((item: any) => {
            item.forEach((innerItem: any, index: number) => {

                achs[innerItem.code] = {
                    id: innerItem.code,
                    type: achievementsServerToFrontId[innerItem.type],
                    unlocked: innerItem.isUnlocked,
                    level: index,
                    innerId: innerItem.buildingId ?? 0,
                    target: innerItem.target
                }

            })
        })

        dispatch(getDispatchObject(SET_ACHIEVEMENTS, achs))
    }

    const fetchBoosts = async () => {
        const response = await fetchData('/boosts/get')
        if (response.error) return

        const result = response.result.filter((item: any) => item.id in boostsServerToFrontId).map((item: any) => {
            return {
                id: boostsServerToFrontId[item.id],
                level: item.level,
                price: item.updateCost,
                isPremium: item?.updateCostCurrency !== 'GOLD'
            }
        })

        // Freeze boost
        // result.push(...[
        // {
        //     id: 'freeze',
        //     name: 'Energy freeze for 1 minute',
        //     price: 1,
        //     level: 0,
        //     isPremium: true,
        //     description: 'Freeze your energy for exactly 1 minute and collect coins',
        //     effect: null
        // }])

        dispatch(getDispatchObject(SET_BOOSTS, result.reduce((res: any, item: any) => ({ ...res, [item.id]: item}), {})))
    }

    return (
        <>
            <ScreenLoader
                content={selector.screenPopup}
                progress={progressInit + progressData}
            />

            {
                selector.screenPopup === null && showOnboarding && (progressInit + progressData === 100) ?
                <Onboarding onClose={() => {
                    setShowOnboarding(false)
                    sendAnalytics('tutorial_complete')
                }}/> : null
            }


            <BrowserRouter>
                <div
                    className='Base--wrapper flex flex-col h-full w-full gap-2'
                >
                    <Routes>
                        <Route path={ROUTE_HOME} element={<Home />} />
                        <Route path={ROUTE_BUILDINGS} element={<Buildings />} />
                        <Route path={ROUTE_FRIENDS} element={<Friends />} />
                        <Route path={ROUTE_FRIENDS_BONUS} element={<FriendsBonus />} />
                        <Route path={ROUTE_TASKS} element={<Tasks />} />
                        <Route path={ROUTE_BOOSTS} element={<Boosts />} />
                        <Route path={ROUTE_STATISTICS} element={<Statistics />} />
                        <Route path={ROUTE_BALANCE} element={<Balance />} />
                        <Route path={ROUTE_ACHIEVEMENTS} element={<Achievements />} />
                        <Route path={ROUTE_PROFILE} element={<Profile />} />
                        <Route path={ROUTE_LEAGUE} element={<League />} />
                        <Route path={ROUTE_PRIVATE_SALE} element={<PrivateSale />} />
                        <Route path={ROUTE_PUBLIC_SALE} element={<PublicSale />} />
                        <Route path={ROUTE_GIVEAWAY} element={<Giveaway />} />
                        <Route path={ROUTE_PREMIUM} element={<Premium />} />
                        
                        <Route path="*">panel not found</Route>
                    </Routes>
                    <Navigation />
                    <>
                        <SnackBar />
                        <ErrorBar />

                        <BoostBuyConfirmModal />
                        <OfflineMiningModal />
                        <TasksDailyModal />
                        <TasksDefaultModal />
                        <LevelUpModal />
                        <BoostsPremiumModal />
                        <BoostsNormalModal />
                        <BoostsBuyBuildings />
                        <DonateModal />
                        <ExchangeModal />
                        <WithdrawalModal />
                        <BuyCrystalsModal />
                        <BankDescriptionModal />
                        <BalanceUpdateModal />
                        <AchievementModal />
                        <AdModal />
                        <BuyCBCModal />
                    </>
                </div>
            </BrowserRouter>
        </>
    );
};

export default App;
