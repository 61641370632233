import React, {FC, useEffect, useMemo, useState} from 'react';
import Panel from "../../components/Panel/Panel";
import InviteFriend from "./components/InviteFriend/InviteFriend";
import InviteInfo from "./components/InviteInfo/InviteInfo";
import {useNavigate} from "react-router-dom";
import {ROUTE_FRIENDS_BONUS} from "../../routes";
import FriendsListSkeleton from "./components/FriendsListSkeleton/FriendsListSkeleton";
import {useTranslation} from "react-i18next";
import {fetchData, getTelegramImageUrl} from "../../utils/api";
import OverflowIcon from '../../components/OverflowIcon/OverflowIcon';
import CrystalBase from "../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../assets/icons/currency/crystal/Shadow";
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import './Friends.css'
import FriendsItem from './components/FriendsItem/FriendsItem';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_FRIENDS, DefaultStateType, getDispatchObject } from '../../store/reducer';

const limit = 100

const Friends: FC = ({}) => {

    const friendsList = useSelector((s: DefaultStateType) => s.friends)
    const count = useSelector((s: DefaultStateType) => s.friendsCount)
    const levelMap = useSelector((s: DefaultStateType) => s.levels)
    const levels = useMemo(() => {
        return levelMap ?? null
    }, [levelMap])

    const [loading, setLoading] = useState(true)
    const [txLoad, setTxLoad] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const dispatch = useDispatch()

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (loading && friendsList !== null) {
            setLoading(false)
            if (friendsList.length < limit) {
                setLoadMore(false)
            } else {
                setLoadMore(true)
            }
        }
    }, [friendsList])

    const fetchFriends = async () => {
        if (txLoad || loading) return
        setTxLoad(true)
        if (!levels || !friendsList || friendsList.length === 0) return
        const offset = friendsList.length
        const response = await fetchData(`/friends/get`, { offset })
        if (response.error) return
        if (response.result.items.length < limit) setLoadMore(false)

        dispatch(getDispatchObject(ADD_FRIENDS, response.result?.items && response.result.items.length > 0 ? response.result.items.map((item: any) => {
            return {
                name: item.firstName ? item.firstName : "Anonymous",
                photo: item.avatar ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + (item.avatar)) : null,
                crystals: item.profitDiamonds,
                level: levels[item.level],
                gold: item.goldPerSecond,
                premium: item.premium ?? false // FIX WHEN API
            }
        }) : []))
        setTxLoad(false)

    }

    return (
        <Panel spaceBetween={false} bottomElement={<InviteFriend />} scrollable={true}>
            <>
                <div className='flex items-center justify-center gap-3'>
                    <p className='font-bold text-2xl text-white'>
                        {t('friendsInviteFor')}
                    </p>
                    <OverflowIcon 
                        Base={<CrystalBase width='24px' height='36px' />}
                        Shadow={<CrystalShadow width='51px' height='44px' />}
                        shadowOffsetTop={22}
                        shadowOffsetLeft={-14}
                        width={24}
                        height={36}
                    />
                </div>

                <InviteInfo />

                <div 
                    className='InviteInfo--link flex mt-4 bg-white/5 rounded-2xl px-4 py-3 items-center justify-between'
                    onClick={() => navigate(ROUTE_FRIENDS_BONUS)}
                >
                    <div className='flex font-bold text-lg gap-2'>
                        {t('friendsExtra')}
                        <OverflowIcon 
                            Base={<CrystalBase width='14px' height='24px' />}
                            Shadow={<CrystalShadow width='31px' height='27px' />}
                            shadowOffsetTop={13}
                            shadowOffsetLeft={-8}
                            width={14}
                            height={24}
                        />
                        {t('friendsForLeague')}
                    </div>
                    <Icon24ChevronRight />
                </div>
            </>
            
            <>
                <div className='InviteInfo--friendstitle mt-6 mb-3 flex'>
                    <p className='tracking-wider text-sm font-bold uppercase'>
                        {t('friendsTitle')}
                    </p>
                    {
                       friendsList !== null && count > 0 && (
                            <div className='ml-1.5 px-1.5 font-semibold text-sm text-white bg-white/10 rounded-2xl'>
                                {count}
                            </div>
                       ) 
                    }
                </div>
                <div className='IntiveInfo--friendslist' id='friendList'>
                    {loading && <FriendsListSkeleton />}

                    {
                        friendsList !== null && friendsList.length > 0 ?
                            friendsList.map((item: any, index: number) => 
                                <FriendsItem
                                    key={'friend_' + item.name + item.gold + index}
                                    imgSrc={item.photo}
                                    name={item.name}
                                    gold={item.gold}
                                    level={item.level}
                                    crystals={item.crystals}
                                    premium={item.premium}
                            />):
                        <div className='InviteInfo--nofriends flex flex-col items-center justify-center'>
                            <img width={152} height={152} className='object-contain h-full' src={require('../../assets/images/extras/nofriends.png')} alt="" />
                            <p className="text-lg text-white font-normal">{t('friendsYourFriendsListEmpty')}</p>
                        </div>
                    }
                </div>
            </>

        </Panel>
    );
};

export default Friends;